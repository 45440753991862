@import '../../../styles/media';
@import '../../../styles/colors';

$padding: 5px;

.main-tiles-wrapper {
  display: grid;
  grid-gap: 16px;
  grid-template-columns: repeat(3, 1fr);
  padding-top: 10px;

  &__top-padding {
    padding-top: 4px;
  }

  @include tablet-large-down {
    grid-template-columns: repeat(2, 1fr);
  }

  @include tablet-medium-down {
    grid-template-columns: 1fr;
  }

  @include phone {
    display: flex;
    flex-wrap: wrap;
    margin-left: -$padding;
    margin-right: -$padding;
    flex-direction: column;
    margin-left: 0;
    margin-right: 0;
  }
}

.tile-news-content-wrapper {
  cursor: pointer;
}

.tile-news-wrapper {
  &:hover {
    .tile-news-title {
      color: $main;
    }
  }
}

.tile-news-img-wrapper {
  height: 130px;
}

.tile-news-img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: 50% 50%;
}

.tile-news-title {
  display: inline-block;
  margin-top: 5px;
  -webkit-transition: color 0.2s;
  -moz-transition: color 0.2s;
  -ms-transition: color 0.2s;
  -o-transition: color 0.2s;
  transition: color 0.2s;
  line-height: 18px;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  display: -webkit-box;
  overflow: hidden;
  text-overflow: ellipsis;
  word-break: break-word;
  font-size: 14px;
  max-height: 55px;

  @include tablet-large-down {
    font-size: 14px;
    line-height: 18px;
  }
}
