@import '../../../styles/colors';

.button {
  display: block;
  padding: 0 15px;
  color: $main;
  font-size: 14px;
  line-height: 46px;
  text-align: center;
  background-color: rgba(103, 58, 183, 0.1);

  &--filled {
    color: $white;
    background-color: $main;
  }

  &--success {
    color: $white;
    background-color: #48b741;
    text-transform: uppercase;
  }
}
