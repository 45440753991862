@import '../../../styles/colors';
@import '../../../styles/media';
@import '../../../styles/typography';

.main-news-alternative {
  display: block;
  color: $white;
  margin-left: -10px;
  margin-right: -10px;
  position: relative;
  padding-top: 71%;

  &__container {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

  &__meta {
    position: absolute;
    width: 100%;
    bottom: 0;
    left: 0;
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    flex-wrap: wrap;
    padding-left: 20px;
    background: rgba(0, 0, 0, 0)
      linear-gradient(to bottom, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.7) 100%)
      repeat scroll 0 0;
    box-sizing: border-box;
    padding-top: 30px;
  }

  &__title {
    flex: 1;
    flex-basis: 100%;
    font-size: 20px;
    line-height: 25px;
    padding-right: 20px;
    margin-bottom: 20px;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    word-break: break-word;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    font-family: $newsTitleFont;
  }

  &__buttons-block {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-basis: 100%;
  }

  &__source {
    text-transform: uppercase;
    font-size: 12px;

    svg {
      width: 10px;
      height: 10px;
      margin-right: 5px;

      path {
        fill: #fff;
      }
    }
  }

  &__button {
    text-transform: uppercase;
    background: $main;
    padding: 5px 10px 5px;
    line-height: 30px;
    font-size: 12px;

    svg {
      width: 10px;
      height: 10px;
      transform: rotate(180deg);
      margin-left: 5px;

      path {
        fill: $white;
      }
    }
  }

  &__image {
    width: 100%;
    height: 100%;
    display: block;
    object-fit: cover;
  }
}
