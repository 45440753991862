@import '../../../styles/media';
@import '../../../styles/colors';

.wrapper {
  text-decoration: none;
  display: block;
  &:hover {
    .title {
      color: $main;
    }
  }
}

.image-wrapper {
  display: flex;
  justify-content: center;
  flex-flow: column;

  @include phone {
    margin-left: -10px;
    margin-right: -10px;
  }
}

.image {
  max-width: 100%;
  object-fit: cover;
  object-position: center;
  width: 100%;
}

.title {
  display: flex;
  font-size: 20px;
  font-weight: 400;
  line-height: 28px;
  color: #333;
  margin-top: 10px;
  -webkit-transition: color 0.2s;
  -moz-transition: color 0.2s;
  -ms-transition: color 0.2s;
  -o-transition: color 0.2s;
  transition: color 0.2s;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  display: -webkit-box;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-bottom: 10px;

  @include tablet-large-down {
    font-size: 22px;
    line-height: 25px;
    font-weight: bold;
  }
}

.sources {
  display: flex;
  color: $textColorSecondary;
  margin-top: 5px;
  margin-bottom: 15px;
  font-weight: bold;
  font-size: 16px;
  line-height: 23px;
  font-family: Euclid;
  display: none;
}

.button {
  display: block;
  padding: 0 15px;
  color: #673ab7;
  font-size: 14px;
  line-height: 46px;
  text-align: center;
  background-color: rgba(103, 58, 183, 0.1);
  -webkit-transition: all 0.2s;
  -moz-transition: all 0.2s;
  -ms-transition: all 0.2s;
  -o-transition: all 0.2s;
  transition: all 0.2s;
  &:hover {
    background-color: #673ab7;
    color: #fff;
  }
  @include tablet {
    margin-top: 7px;
  }
}
