@import '../../../styles/colors';
@import '../../../styles/media';
@import '../../../styles/typography';

.header {
  background-color: $headerBackground;
  height: 40px;
  position: sticky;
  top: 0;
  font-family: $headerFont;
  z-index: 1;

  @include tablet-down {
    height: 50px;
  }

  @include phone {
    position: static;
  }

  &--mobile {
    background-color: $dark-black;
  }

  &__container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 100%;

    @include tablet-down {
      padding: 0;
    }
  }

  &__logo {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    height: 100%;
    flex-basis: 15%;

    svg {
      height: 100%;
      width: auto;

      path {
        fill: $main;

        &:nth-child(2) {
          fill: $white;
        }
      }
    }

    .header-title {
      color: #673ab7;
      align-self: flex-end;
      padding-bottom: 5px;
      font-size: 14px;
      text-transform: uppercase;
      font-weight: 700;
      letter-spacing: 1px;
    }
  }

  &__logo--mobile {
    flex-basis: 15%;
  }

  &__logo-title {
    color: $main;
    align-self: flex-end;
    padding-bottom: 5px;
    font-size: 14px;
    text-transform: uppercase;
    font-weight: bold;
    letter-spacing: 1px;

    @include tablet-down {
      padding-bottom: 5px;
      font-size: 23px;
    }
  }

  &__title {
    flex-basis: 15%;
    font-size: 34px;
    color: $white;
    display: flex;
    text-transform: uppercase;
    margin-left: 11px;
    font-weight: bold;
    line-height: 26px;

    span {
      color: #f9e552;
    }
  }

  &__menu-button-wrapper {
    flex-basis: 15%;
    display: flex;
    align-items: center;
    justify-content: flex-end;

    &.hidden {
      visibility: hidden;
    }
  }

  &__menu-button {
    width: 30px;
    padding-top: 2px;
    padding-bottom: 2px;
    margin-right: 10px;

    position: relative;
    cursor: pointer;

    @include tablet {
      display: none;
    }

    &--open {

      &:before,
      &:after,
      div {
        right: 2px!important;
      }

      &:before {
        transform: rotate(45deg);
      }

      &:after {
        transform: rotate(-45deg);
      }

      div {
        opacity: 0;
      }
    }

    div {
      width: 100%;
      height: 2px;
      margin-top: 8px;
      margin-bottom: 8px;
      background-color: $white;
      transition: all 0.3s ease-in-out;
    }

    &:after,
    &:before {
      content: '';
      display: block;
      position: absolute;
      left: 0;
      right: 0px;
      height: 2px;
      background-color: $white;
      transition: all 0.3s ease-in-out;
      transform-origin: 0;
    }

    &:before {
      top: 0;
    }

    &:after {
      bottom: 0;
    }
  }

  &__mobile-menu {
    position: relative;
    opacity: 0;
    pointer-events: none;
    transition: opacity 0.3s;
    background-color: $dark-grey;
    padding-bottom: 1em;

    &--open {
      opacity: 1;
      pointer-events: initial;
      z-index: 2;
    }

    h5 {
      opacity: 0.4;
      display: block;
      width: 100%;
      color: $white;
      font-weight: bold;
      border-bottom: 1px solid $white;
      padding: 25px 15px 10px;
      box-sizing: border-box;
    }

    @include tablet {
      display: none;
    }
  }

  &__hide-area {
    position: absolute;
    z-index: -1;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: rgba(0,0,0,0.4);
  }
}
