@import './../../../styles/colors';
@import './../../../styles/media';

$containerWidth: 916px;

%news-title {
  font-size: 20px;
  line-height: 1.2;
  font-weight: bold;
  word-break: break-word;
}

%news-source {
  font-size: 14px;
  white-space: nowrap;
  text-transform: uppercase;
  line-height: 1.2;
  text-overflow: ellipsis;
  overflow-x: hidden;
  overflow-y: hidden;
  cursor: pointer;
  letter-spacing: 0.875px;
  font-weight: bold;
  color: rgba(0, 0, 0, 0.6);
  margin-bottom: 0px;
}

.sticky-container--zen {
  .sticky-container__arrow {
    background-color: #fff;
    svg path {
      fill: #333;
    }
  }
}

.header--zen {
  &.header {
    height: 58px;
    background: #fff;
    margin-bottom: 2px;

    @include phone {
      height: 58px;
      padding-top: 0;
      margin-bottom: -5px;
    }

    &--mobile {}

    // abc-58: mobile header on desktop
    &--always-mobile {
      padding-top: 4px;

      @include phone {
        padding-top: 0;
        margin-bottom: -10px;
      }

      .header {
        &__menu-button {
          display: block;

          &-wrapper {
            display: flex;
          }
        }

        &__mobile-menu {
          display: block;
        }
      }
    }
  }

  .header {

    &__container {
      padding: 0;
      box-sizing: border-box;
      max-width: $containerWidth;
      align-items: flex-end;

      @include phone {
        padding: 0 20px;
      }
    }

    &__logo {

      .title {
        margin-bottom: -15px;
        color:rgb(17, 17, 17);
        font-weight: 500;
        line-height: 1;
        font-family: Euclid, sans-serif;
        font-size: 30px;
        margin-left: 2px;
        text-transform: lowercase;
      }

      .dot {
        fill: #111111;
      }
    }

    &__title {
      margin-left: 0;
      color: #000000;
      font-weight: normal;
      font-size: 24px;
      line-height: 1;
      display: flex;
      align-items: flex-end;

      span {
        font-size: 34px;
        margin-bottom: 5px!important;
        color: #333;
      }
    }

    &__menu-button {
      margin-right: 0;

      &:before,
      &:after,
      > div {
        background-color: #000000;
        height: 3px;
      }

      &-wrapper {
        align-self: center;

        @include desktop {
          display: none;
        }
      }
    }

    &__mobile-menu {
      padding: 0px;
      background-color: #fff;

      .container {
        padding: 20px;
        background-color: #fff;

        .nav {
          max-width: $containerWidth;
          margin: 0 auto;
          padding: 0;

          &__item {
            color: #333;
          }
        }
      }
    }
  }

  .nav {
    .nav__item {
      font-size: 17px;
      color: #111111;
      padding: 5px 7px;
      font-family: Euclid, sans-serif;
      white-space: nowrap;
      box-sizing: border-box;
      margin-left: 0;

      &:last-child {
        padding-right: 0;
      }
    }
  }
}

.app-zen {

  //&:before {
  //  content: '';
  //  position: fixed;
  //  opacity: 0.5;
  //  top: -200px; // label YANDEX
  //  left: 0;
  //  width: 100%;
  //  height: 100%;
  //  z-index: 2;
  //  background: url('yandex.jpg') center center no-repeat;
  //  background-size: 100% auto;
  //}

  // label APP-DESKTOP
  &.app-desktop {

    // NEWS WRAPPER
    .main-tiles-wrapper {
      padding-top: 0;
      grid-row: 3 / 3;
      grid-gap: 8px;

      .news-item-wrapper {

        //> *:nth-child(7n + 1) {
        &--left {
          grid-column-start: 1;
          grid-column-end: 3;
        }
        //> *:nth-child(7n + 4) {
        &--right {
          grid-column-start: 2;
          grid-column-end: 4;
        }

        &--left,
        &--right {
          .detail-news-alternative {
            &__container,
            &__container {
              display: flex;
              flex-direction: row-reverse;
              align-items: flex-start;

              img {
                margin: 0;
                padding: 0;
                width: auto;
              }
            }
            &__meta,
            &__meta {
              display: block;
              align-items: flex-start;
              padding: 20px 0 12px 23px;
              height: 320px;

              > * {
                width: 50%;
              }
            }
          }
        }
      }
    }
  }

  .container {
    //opacity: 0.75;
    padding: 20px 20px;
    font-size: 0;

    @include desktop {
      padding-top: 10px;
      width: $containerWidth;
    }
  }

  .news-item-wrapper {
    border-radius: 7px;
    overflow-x: hidden;
    bottom: 0;
    position: relative;

    @include phone {
      margin-bottom: 16px;
    }

    .detail-news-alternative {
      margin-bottom: 0;
    }
  }



  // label NEWS BLOCK
  .detail-news-alternative {
    margin: 0 0 16px;
    border-radius: 7px;
    overflow-x: hidden;
    padding-top: 0;

    &__container {
      top: 0;
      bottom: 0;

      &:after {
        content: '';
        position: absolute;
        top: -100%;
        left: 0;
        width: 100%;
        height: 100%;
        transition: background-color 0.5s;
      }

      &:hover{
        &:after {
        top: 0;
        background-color: rgba(0,0,0,0.25);
        transition: background-color 0.5s;
        }
      }

      @include phone {
        position: relative;
      }
    }

    &__meta {
      padding: 40px 24px 58px;
      //background: rgba(0, 0, 0, 0)
      //  linear-gradient(
      //    to bottom,
      //    transparent 20px,
      //    #111 calc(50% - 25px),
      //    #111 100%
      //  )
      //  repeat scroll 0 0;
    }

    &__image {
      max-height: none;
    }
    &__meta-wrapper  {
      height: 60%;
      width: 100%;
      position: absolute;
      top: 0;
      right: 0;
    }

    &__title {
      padding-right: 0;
      margin-bottom: 0;
      @extend %news-title;
    }

    &__description {
      font-size: 14px;
      line-height: 1.4;
      opacity: 0.9;
      margin-top: 8px;
    }

    &__footer {
      position: absolute;
      bottom: 0;
      left: 0;
      box-sizing: border-box;
      padding: 12px 24px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 100%;

      @include desktop {
        max-width: 300px;
      }
    }

    &__source {
      @extend %news-source;
      opacity: 0.4;

      svg {
        display: none;
      }
    }

    &__button {
      display: none;
    }
  }

  .likes {
    position: relative;
    cursor: pointer;
    display: flex;
    justify-content: space-between;
    align-items: center;

    svg {
      width: 28px;
      margin-top: 8px;
      opacity: 0.4;
      transition: opacity 0.5s;

      &:first-child {
        padding-right: 10px;
      }
      &:last-child {
        padding-right: 10px;
      }
    }
  }
}
