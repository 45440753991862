@import '../../../styles/media';

.top-block {
  display: grid;
  grid-gap: 16px;
  grid-template-columns: 5fr 9fr;
  grid-template-columns: repeat(14, 1fr);
  padding: 16px 0;
  border-bottom: 1px solid #e3e4e6;
  margin-bottom: 16px;

  .main-news {
    grid-column-end: span 5;

    @include tablet-large-down {
      grid-column-end: span 6;
    }
  }

  .news {
    grid-column-end: span 9;

    @include tablet-large-down {
      grid-column-end: span 8;
    }

    .tile-news-title {
      min-height: 54px;
      margin-bottom: 5px;

      @include tablet-large-down {
        min-height: initial;
        margin-bottom: 0;
      }
    }
  }

  .news {
    display: grid;
    grid-gap: 16px;
    grid-template-columns: repeat(3, 1fr);

    grid-auto-rows: min-content;

    @include tablet-large-down {
      grid-template-columns: repeat(2, 1fr);
    }
  }

  &--ga {
    grid-template-columns: 5fr 4fr 3fr;

    @include tablet {
      max-height: 404px;
      overflow-x: hidden;
      overflow-y: hidden;
      grid-template-columns: 5fr 5fr 4fr;
    }
    @include desktop {
      grid-template-columns: 5fr 6fr 3fr;
    }

    > * {
      grid-column-end: span 1 !important;
    }

    > .news {
      @include tablet {
        grid-template-columns: repeat(2, 1fr);
      }
    }
  }

}
