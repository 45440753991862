@import './styles/css-reset';
@import './styles/fonts';
@import './styles/typography';
@import './styles/media';
@import './styles/colors';
@import './styles/abc';

.app {
  // font: 12px 'PT Sans', Arial, Myriad Pro, Tahoma, Geneva, sans-serif;
  // font-family: 'Roboto Condenced', sans-serif;
  font: 400 16px/1.5 $basicFont, sans-serif;
  -webkit-text-size-adjust: 100%;
}

.content {
  padding: 10px 0;
}

.container {
  margin: 0 auto;
  max-width: 1244px;
  padding: 0 25px;

  @include phone {
    padding: 0 10px;
  }
}

.grid {
  display: grid;
  grid-gap: 16px;
  grid-template-columns: repeat(14, 1fr);
}

.b-column-wrapper {
  @include phone {
    display: block;
  }
}

.b-left-column {
  grid-column-end: span 3;

  @include tablet-large-down {
    grid-column-end: span 4;
  }
}

.b-center-column {
  grid-column-end: span 8;

  @include tablet-large-down {
    grid-column-end: span 6;
  }

  @include phone {
    margin: 0;
  }
}

.b-right-column {
  grid-column-end: span 3;

  @include tablet-large-down {
    grid-column-end: span 4;
  }
}

.news-item-wrapper {
  width: 100%;
}

.news-container {
  margin-top: 14px;

  &:first-child {
    margin-top: 0;
  }

  &:first-of-type {
    margin-top: 0;
    padding-top: 0;
  }

  &__margin-top {
    margin-top: 42px;
    @include tablet {
      margin-top: 20px;
    }
  }

  @include phone {
    margin: 0;
    margin-top: 10px;
    padding-top: 10px;

    &:not(:first-of-type) {
      border-top: 1px solid $borderColor;
    }

    &--hidden {
      display: none;
    }
  }
}

.mix {
  background-color: #f9f9f9;
}

.noSelect {
  //-webkit-touch-callout: none;
  //-webkit-user-select: none;
  //-khtml-user-select: none;
  //-moz-user-select: none;
  //-ms-user-select: none;
  //user-select: none;
  -webkit-tap-highlight-color: rgba(255, 255, 255, 0);
}