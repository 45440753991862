@import '../../../styles/media';
@import '../../../styles/colors';
@import '../../../styles/typography';

.title-category {
  font-size: 22px;
  line-height: 28px;
  color: $main;
  font-weight: 700;
  padding-bottom: 10px;
  font-family: $headerFont;

  @include phone {
    padding-bottom: 0;
  }
}
