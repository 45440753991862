@import 'typography';

// label: Basic tests
.app {
  // Font: Roboto
  &--abc-55 {
    .detail-news-alternative {
      &__title,
      &__footer {
        font-family: $robotoFont;
      }
    }
  }
  // Font: Open Sans
  &--abc-56 {
    .detail-news-alternative {
      &__title,
      &__footer {
        font-family: $openSansFont;
      }
    }
  }
  // Font: Euclid
  &--abc-57 {
    .detail-news-alternative {
      &__title,
      &__footer {
        font-family: $euclidFont;
      }
    }
  }

}