@import '../../../styles/colors';

.sticky-container {
  position: sticky;
  left: 0;
  width: 100%;
  top: -45px;
  z-index: 1;
  transition: top 0.3s;

  &--shown {
    top: 0;

    svg {
      transform: rotate(180deg);
    }
  }

  &__children {
    position: relative;
    z-index: 3;
  }

  &__arrow-container {
    //border-top: 5px solid $dark-black;
    display: flex;
    justify-content: center;
    overflow: hidden;
    position: absolute;
    width: 100%;
    top: 100%;
    transition: top 0.3s;

    &--out {
      top: 0;
    }
  }

  &__arrow {
    width: 60px;
    height: 29px;
    background-color: $dark-black;
    border-bottom-left-radius: 100px;
    border-bottom-right-radius: 100px;
    box-sizing: border-box;
    position: relative;
    top: -8px;

    svg {
      width: 20px;
      height: 19px;
      position: absolute;
      top: 6px;
      left: 20px;
      z-index: 1;

      path {
        fill: $white;
      }
    }
  }
}
