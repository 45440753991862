// Font families
$defaultFont: sans-serif;
$robotoFont: 'Roboto', sans-serif;
$euclidFont: 'Euclid', sans-serif;
$openSansFont: 'Open Sans', sans-serif;

// Usage
$basicFont: $defaultFont;
$headerFont: $euclidFont;
$newsTitleFont: $defaultFont;
$newsDescriptionFont: $defaultFont;
$newsFooterFont: $defaultFont;

