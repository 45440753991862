// Fonts
$font-dir: 'fonts';

// Fonts - Euclid
@font-face {
  font-family: 'Euclid';
  font-weight: 400;
  font-style: normal;
  font-display: swap;
  src: url('#{$font-dir}/euclidflex-regular.eot'),
  url('#{$font-dir}/euclidflex-regular.woff2') format('woff2'),
  url('#{$font-dir}/euclidflex-regular.woff') format('woff');
}