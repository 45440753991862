@import '../../../styles/colors';

.loader {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 0;
  display: flex;
  flex-flow: column nowrap;
  width: 100vw;
  height: 100vh;
  justify-content: center;
  align-items: center;
  color: $main;

  &.inline {
    height: auto;
    grid-column: 1 / -1;
    transform: translateX(-3%) scale(0.6);
  }

  &--active {
    background: rgba(255, 255, 255, 0.5);
  }

  & > div > span {
    background-color: currentColor;
    width: 25px;
    height: 25px;
    margin: 2px;
    border-radius: 100%;
    display: inline-block;
    animation: loading 0.75s 0.12s infinite cubic-bezier(0.2, 0.68, 0.18, 1.08);
    animation-fill-mode: both;

    &:nth-child(2) {
      animation-delay: 0.24s;
    }

    &:nth-child(3) {
      animation-delay: 0.36s;
    }
  }
}

@keyframes loading {
  0% {
    opacity: 0.2;
  }

  20% {
    opacity: 1;
  }

  100% {
    opacity: 0.2;
  }
}
