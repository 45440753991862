@import '../../../styles/media';
@import '../../../styles/colors';
@import '../../../styles/typography';

.detail-link {
  padding: 10px 0;
  display: block;
  border-bottom: 1px solid $borderColor;

  &.alternative {
    padding: 28px 24px;
    background: #f5f5f5;
    border: 1px solid $borderColor;

    .news-item-wrapper:not(:first-child) & {
      border-top: 0;
      &:not(:first-child) {
        border-top: 0;
      }
    }

    &.hot {
      background: #f5fff4;
      border-left-width: 3px;
      border-left-color: #48b741;
    }

    .detail-meta {
      margin-bottom: 15px;
    }

    .detail-title {
      font-size: 16px;
      color: #222;
      font-weight: 400;
      line-height: 22px;
    }
  }

  &--with-meta {
    padding: 5px 0 10px;
  }

  @include phone {
    .news-item-wrapper:last-child & {
      border: 0;
    }
  }

  &:hover {
    .detail-title {
      color: $main;
    }
  }
}

.detail-wrapper {
  display: flex;
  cursor: pointer;
  flex: 0 0 100%;

  &--right {
    flex-flow: row-reverse;

    .img-wrapper {
      margin-right: 0;
      margin-left: 14px;
    }
  }
}

.img-wrapper {
  width: 80px;
  height: 80px;
  flex-shrink: 0;
  margin-right: 14px;

  &--medium {
    width: 100px;
    height: 100px;
  }
}

.detail-img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: 50% 50%;
}

.detail-content-wrapper {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
}

.detail-title {
  color: #333;
  font-size: 14px;
  line-height: 16px;
  display: -webkit-box;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  word-break: break-word;

  -webkit-transition: color 0.2s;
  -moz-transition: color 0.2s;
  -ms-transition: color 0.2s;
  -o-transition: color 0.2s;
  transition: color 0.2s;

  @include tablet-large-down {
    font-size: 16px;
    line-height: 19px;
    -webkit-line-clamp: 4;
    max-height: 77px;
  }

  @include phone {
    max-height: initial;
    -webkit-line-clamp: 3;

    font-size: 16px;
    line-height: 22px;

    .detail-wrapper--large & {
      font-size: 18px;
      line-height: 22px;
      font-weight: bold;
    }
  }
}

.detail-meta {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 5px;
  font-size: 11px;
  letter-spacing: 0.2px;
  font-weight: bold;

  @include phone {
    font-size: 13px;
    line-height: 16px;
    margin-bottom: 7px;
  }
}

.detail-source {
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: rgba(32, 33, 36, 0.8);

  font-size: 13px;
  line-height: 16px;

  span {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }

  &--left {
    justify-content: flex-start;
  }

  &--with-margin {
    margin-top: 10px;
  }

  .detail-wrapper--large & {
    font-size: 15px;
  }
}

.detail-date {
  white-space: nowrap;
}

.detail-source-image {
  margin-right: 5px;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    display: block;
  }
}

.detail-news-alternative {
  display: block;
  width: 100%;
  color: $white;
  font-family: $newsTitleFont;
  margin-bottom: 10px;
  position: relative;
  padding-top: 74%;

  &__container {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

  &__meta {
    position: absolute;
    width: 100%;
    bottom: 0;
    left: 0;
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    flex-wrap: wrap;
    padding-left: 20px;
    background: rgba(0, 0, 0, 0)
      linear-gradient(to bottom, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.7) 100%)
      repeat scroll 0 0;
    box-sizing: border-box;
    padding-top: 20%;
  }

  &__title {
    flex: 1;
    flex-basis: 100%;
    font-size: 20px;
    line-height: 25px;
    padding-right: 20px;
    margin-bottom: 11px;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    word-break: break-word;
    display: -webkit-box;
  }

  &__description {
    font-family: $newsDescriptionFont;
  }

  &__footer {
    font-family: $newsFooterFont;
  }

  &__source {
    text-transform: uppercase;
    font-size: 12px;
    margin-bottom: 10px;

    svg {
      width: 10px;
      height: 10px;
      margin-right: 5px;

      path {
        fill: #fff;
      }
    }
  }

  &__image {
    width: 100%;
    height: auto;
    display: block;
    max-height: 100%;
    object-fit: cover;
  }
}
