@import '../../../styles/colors';
@import '../../../styles/media';

.nav {
  display: flex;
  flex: 1;
  justify-content: center;

  &__item {
    color: $textColorSecondary;
    text-decoration: none;
    font-size: 15px;
    line-height: 1.6;
    transition: 0.3s;

    @include tablet-medium-down {
      font-size: 11px;
    }

    &:not(:first-child) {
      margin-left: 20px;

      @include tablet-medium-down {
        margin-left: 10px;
      }
    }

    &:hover,
    &--active {
      color: $main;
    }
  }

  &--mobile {
    flex-wrap: wrap;
    padding: 30px 15px 0;

    .nav__item {
      flex-basis: 50%;
      margin-left: 0;
      font-size: 17px;
      font-weight: normal;
      margin-bottom: 15px;
      color: $white;

      &--active {
        color: #f9e552;
      }
    }
  }
}
