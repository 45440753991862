@import '../../../../styles/typography';

.btn-logo {
  position: relative;
  width: 50px;
  height: 50px;
  // margin: 24px 12px;
  //overflow: hidden;

  & > * {
    box-sizing: border-box;
  }

  &-slider {
    perspective: 1000px;
    transform-style: preserve-3d;
    transform-origin: 0% 0%;
    transform: translateZ(0) scale(0.6); // 48px
    @media (min-width: 768px) {
      transform: translateZ(0) scale(0.741);
    } // 60px
    @media (min-width: 1440px) {
      transform: translateZ(0) scale(1);
    } // 84px
  }

  &-item {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    &-container {
      position: relative;
    }
    &-piece {
      span {
        position: absolute;
        bottom: 4px;
        right: 3px;
        color: #fff;
        font-family: $basicFont;
        font-size: 13px;
        font-weight: normal;
      }
      position: absolute;

      opacity: 0;
      transition: transform 0.85s cubic-bezier(0.165, 0.84, 0.44, 1),
        opacity 0.85s cubic-bezier(0.165, 0.84, 0.44, 1);

      &:nth-child(1) {
        transform: translateZ(200px) translateY(100%) rotateY(90deg);
        transition-delay: 0.15s, 0.15s;
      }
      &:nth-child(2) {
        transform: translateZ(-50px) translateY(20%) rotateY(90deg);
        transition-delay: 0.5s, 0.5s;
      }
      &:nth-child(3) {
        transform: translateZ(50px) translateX(20%) rotateY(-90deg);
        transition-delay: 0.05s, 0.05s;
      }
      &:nth-child(4) {
        transform: translateZ(50px) translateY(20%) rotateY(90deg);
        transition-delay: 0.35s, 0.35s;
      }
      &:nth-child(5) {
        transform: translateZ(-150px) translateX(120%) rotateY(-90deg);
        transition-delay: 0.5s, 0.5s;
      }
      &:nth-child(6) {
        transform: translateZ(150px) translateX(-120%) rotateY(-90deg);
        transition-delay: 0.35s, 0.35s;
      }
      &:nth-child(7) {
        transform: translateZ(-150px) translateY(-50%) rotateY(90deg);
        transition-delay: 0.5s, 0.5s;
      }
      &:nth-child(8) {
        transform: translateZ(50px) translateX(50%) rotateY(90deg);
        transition-delay: 0.45s, 0.45s;
      }

      .in & {
        opacity: 1;
        transform: translateZ(0) translateY(0%) rotateY(0);
        transition: transform 0.85s cubic-bezier(0.165, 0.84, 0.44, 1),
          opacity 0.85s cubic-bezier(0.165, 0.84, 0.44, 1);
        &:nth-child(1) {
          transition-delay: 0.35s, 0.35s;
        }
        &:nth-child(2) {
          transition-delay: 0.15s, 0.15s;
        }
        &:nth-child(3) {
          transition-delay: 0.45s, 0.45s;
        }
        &:nth-child(4) {
          transition-delay: 0.15s, 0.15s;
        }
        &:nth-child(5) {
          transition-delay: 0.5s, 0.5s;
        }
        &:nth-child(6) {
          transition-delay: 0.25s, 0.25s;
        }
        &:nth-child(7) {
          transition-delay: 0.5s, 0.5s;
        }
        &:nth-child(8) {
          transition-delay: 0.35s, 0.35s;
        }
      }
      .out & {
        opacity: 0;
        transition: transform 0.85s cubic-bezier(0.165, 0.84, 0.44, 1),
          opacity 0.85s cubic-bezier(0.165, 0.84, 0.44, 1);
        &:nth-child(1) {
          transform: translateZ(200px) translateY(100%) rotateY(90deg);
          transition-delay: 0.5s, 0.5s;
        }
        &:nth-child(2) {
          transform: translateZ(-50px) translateY(20%) rotateY(90deg);
          transition-delay: 0.35s, 0.35s;
        }
        &:nth-child(3) {
          transform: translateZ(50px) translateX(20%) rotateY(-90deg);
          transition-delay: 0.15s, 0.15s;
        }
        &:nth-child(4) {
          transform: translateZ(50px) translateY(20%) rotateY(90deg);
          transition-delay: 0.5s, 0.5s;
        }
        &:nth-child(5) {
          transform: translateZ(-150px) translateX(120%) rotateY(-90deg);
          transition-delay: 0.25s, 0.25s;
        }
        &:nth-child(6) {
          transform: translateZ(150px) translateX(-120%) rotateY(-90deg);
          transition-delay: 0.05s, 0.05s;
        }
        &:nth-child(7) {
          transform: translateZ(-150px) translateY(-50%) rotateY(90deg);
          transition-delay: 0.35s, 0.35s;
        }
        &:nth-child(8) {
          transform: translateZ(50px) translateX(50%) rotateY(90deg);
          transition-delay: 0.15s, 0.15s;
        }
      }

      .logo-style-1 & {
        &:nth-child(1) {
          top: 0;
          left: 14px;
          width: 14px;
          height: 28px;
          background: #63bc51;
        }
        &:nth-child(2) {
          top: 28px;
          left: 0;
          width: 28px;
          height: 14px;
          background: #f9e552;
        }
        &:nth-child(3) {
          top: 42px;
          left: 0;
          width: 56px;
          height: 14px;
          background: #80479b;
        }
        &:nth-child(4) {
          top: 14px;
          left: 28px;
          width: 28px;
          height: 28px;
          background: #0095d3;
        }
        &:nth-child(5) {
          top: 35px;
          left: 56px;
          width: 7px;
          height: 7px;
          background: #f05b5f;
        }
        &:nth-child(6) {
          top: 35px;
          left: 63px;
          width: 7px;
          height: 7px;
          background: #63bc51;
        }
        &:nth-child(7) {
          top: 42px;
          left: 56px;
          width: 28px;
          height: 14px;
          background: rgba(30, 30, 30, 1);
        }
        &:nth-child(8) {
          top: 56px;
          left: 14px;
          width: 56px;
          height: 28px;
          background: #f9e552;
        }
      }
      .logo-style-2 & {
        &:nth-child(1) {
          top: 0;
          left: 42px;
          width: 14px;
          height: 28px;
          background: #63bc51;
        }
        &:nth-child(2) {
          top: 28px;
          left: 28px;
          width: 28px;
          height: 14px;
          background: #f9e552;
        }
        &:nth-child(3) {
          top: 42px;
          left: 56px;
          width: 28px;
          height: 42px;
          background: #80479b;
        }
        &:nth-child(4) {
          top: 42px;
          left: 0;
          width: 56px;
          height: 14px;
          background: #0095d3;
        }
        &:nth-child(5) {
          top: 28px;
          left: 14px;
          width: 7px;
          height: 14px;
          background: #f05b5f;
        }
        &:nth-child(6) {
          top: 28px;
          left: 21px;
          width: 7px;
          height: 14px;
          background: #63bc51;
        }
        &:nth-child(7) {
          top: 28px;
          left: 56px;
          width: 28px;
          height: 14px;
          background: rgba(30, 30, 30, 1);
        }
        &:nth-child(8) {
          top: 56px;
          left: 14px;
          width: 42px;
          height: 14px;
          background: #f05b5f;
        }
      }
      .logo-style-3 & {
        &:nth-child(1) {
          top: 0;
          left: 42px;
          width: 14px;
          height: 21px;
          background: #80479b;
        }
        &:nth-child(2) {
          top: 21px;
          left: 14px;
          width: 42px;
          height: 21px;
          background: #f9e552;
        }
        &:nth-child(3) {
          top: 42px;
          left: 42px;
          width: 42px;
          height: 14px;
          background: #63bc51;
        }
        &:nth-child(4) {
          top: 42px;
          left: 0;
          width: 42px;
          height: 28px;
          background: #f37820;
        }
        // display none START
        &:nth-child(5) {
          display: none;
          top: 28px;
          left: 14px;
          width: 7px;
          height: 14px;
          background: #f05b5f;
        }
        &:nth-child(6) {
          display: none;
          top: 28px;
          left: 21px;
          width: 7px;
          height: 14px;
          background: #63bc51;
        }
        // display none END
        &:nth-child(7) {
          top: 28px;
          left: 56px;
          width: 14px;
          height: 14px;
          background: rgba(30, 30, 30, 1);
        }
        &:nth-child(8) {
          top: 70px;
          left: 14px;
          width: 28px;
          height: 14px;
          background: #f05b5f;
        }
      }
      .logo-style-4 & {
        &:nth-child(1) {
          top: 0;
          left: 42px;
          width: 14px;
          height: 28px;
          background: #63bc51;
        }
        &:nth-child(2) {
          top: 14px;
          left: 28px;
          width: 14px;
          height: 14px;
          background: rgba(30, 30, 30, 1);
        }
        &:nth-child(3) {
          top: 28px;
          left: 28px;
          width: 28px;
          height: 14px;
          background: #0095d3;
        }
        &:nth-child(4) {
          top: 42px;
          left: 0;
          width: 56px;
          height: 14px;
          background: #80479b;
        }
        // display none START
        &:nth-child(5) {
          display: none;
          top: 28px;
          left: 14px;
          width: 7px;
          height: 14px;
          background: #f05b5f;
        }
        // display none END
        &:nth-child(6) {
          top: 56px;
          left: 56px;
          width: 28px;
          height: 14px;
          background: #f05b5f;
        }
        &:nth-child(7) {
          top: 42px;
          left: 56px;
          width: 28px;
          height: 14px;
          background: rgba(30, 30, 30, 1);
        }
        &:nth-child(8) {
          top: 56px;
          left: 28px;
          width: 28px;
          height: 28px;
          background: #63bc51;
        }
      }
    }
  }
}
